export enum VideoStatus {
  AVAILABLE = 'available',
  WAITING_TRANSCODING = 'waiting_transcoding',
  TRANSCODING = 'transcoding',
  VM_ERROR = 'vm_error',
  FFMPEG_ERROR = 'ffmpeg_error',
  FILE_ERROR = 'file_error',
  UNKNOWN_ERROR = 'unknown_error',
  TRANSCODING_ERROR = 'transcoding_error',
  UNAVAILABLE = 'unavailable',
  UPLOADING = 'uploading',
  UPLOADING_ERROR = 'uploading_error',
  STREAM_UPLOAD_ERROR = 'stream_upload_error',
  DOWNLOAD_ERROR_UNSUPPORTED_URL = 'download_error_unsupported_url',
  DOWNLOAD_ERROR_FORBIDDEN = 'download_error_forbidden',
  DOWNLOAD_ERROR = 'download_error',
  DOWNLOAD_ERROR_GENERAL = 'download_error_general',
  INTERNAL_DOWNLOAD_ERROR = 'internal_download_error',
}

export const RecoverableStatuses = [
  VideoStatus.VM_ERROR,
  VideoStatus.STREAM_UPLOAD_ERROR,
  VideoStatus.AVAILABLE,
  VideoStatus.UNKNOWN_ERROR,
] as const;

export const getVideoStatusFromBunnyCode = (code: number): VideoStatus => {
  switch (code) {
    case 0:
      return VideoStatus.WAITING_TRANSCODING;

    case 1:
      return VideoStatus.TRANSCODING;

    case 2:
      return VideoStatus.TRANSCODING;

    case 3:
      return VideoStatus.TRANSCODING;

    case 4:
      return VideoStatus.AVAILABLE;

    default:
      return VideoStatus.TRANSCODING_ERROR;
  }
};

export enum VideoProvider {
  VIMEO = 'vimeo',
  B2 = 'b2',
  DO = 'do',
  GCS = 'gcs',
  BUNNY = 'bunny-std',
  BUNNY_HDD = 'bunny-hdd',
  HLS_LINK = 'hlslink',
  YOUTUBE = 'youtube',
}

export interface VideoConversionProgress {
  id: string;
  ready: boolean;
  isHdr: boolean;
  conversionProgress: number;
  status: VideoStatus;
}

export interface Video extends VideoProperties {
  id: string;
}

export interface VideoPostRequiredProperties {
  ready: boolean;
  downloadName?: string;
  status?: VideoStatus;
  uri?: string | null;
  dash?: boolean;
  type?: 'video' | 'audio';
  hlsSrc?: string | null;
  isHdr?: boolean;
  previewImg?: string;
  thumbnail?: string;
  previewThumbnails?: string;
  drm?: boolean;
}

export type AiDataType = 'transcripted' | 'error' | 'processing' | 'ready';

export interface VideoProperties extends VideoPostRequiredProperties {
  id?: string;
  name?: string;
  originalName?: string;
  originalFile?: string;
  link?: string;

  captionSrc?: string;
  language?: string;
  hasAiData?: boolean;

  vimeoThumbV?: number;
  createdAt?: Date;
  size?: number;
  dlSize?: number;
  duration?: number;
  error?: boolean;
  originalError?: string;

  isExternal?: boolean;
  uploadPath?: string;
  linkedTo?: string[];
  isDemo?: boolean;
  provider?: VideoProvider;
  bucket?: string;
  bunnyBucket?: string;
  conversionProgress?: number;
  deleteAt?: Date;

  audioDownload?: boolean;
  hasAudio?: boolean;
  aiData?: AiDataType | null;
}

export interface VideoLibrary {
  videos: Record<string, Video>;
}

export interface UserVideo extends VideoPostRequiredProperties {
  id: string;
  schoolId: string;
  videoType: 'space-video' | 'widget-video' | 'assignment-video';

  duration?: number;
  videoLibraryId: number;
  collectionId: string;
  guid: string;
  bunnyStatus: number;
  availableResolutions?: string | null;
  storageSize: number;
  originalFile: string;
  hlsSrc: string;
  status: VideoStatus;
  previewImg: string;
  thumbnail: string;
  conversionProgress?: number;
}

export interface UserVideoPreview {
  previewImg: string;
}
